import '@datadog/browser-logs/bundle/datadog-logs';
import '@datadog/browser-rum/bundle/datadog-rum';

const moduleFederationURLSuffix = `_next/static/chunks/remoteEntry.js?${Date.now()}`;

window.buildModuleFederationURL = `${process.env.REACT_APP_BUILD_FRONTEND_URL}/${moduleFederationURLSuffix}`;
window.salesModuleFederationURL = `${process.env.REACT_APP_SALES_FRONTEND_URL}/${moduleFederationURLSuffix}`;
window.financialToolsModuleFederationURL = `${process.env.REACT_APP_FINANCIAL_TOOLS_FRONTEND_URL}/${moduleFederationURLSuffix}`;
window.legalDocToolsModuleFederationURL = `${process.env.REACT_APP_LEGAL_DOC_TOOLS_FRONTEND_URL}/${moduleFederationURLSuffix}`;

window.DD_LOGS.init({
  clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
  service: 'tools-dashboard',
  env: window.location.hostname.includes('tools.allocations.com') ? 'production' : 'development',
  sampleRate: 100,
});

window.DD_RUM.init({
  applicationId: '53f69c90-671d-4d9d-bc45-76b9adca9f71',
  clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
  env: window.location.hostname.includes('tools.allocations.com') ? 'production' : 'development',
  service: 'tools-dashboard',
  sampleRate: 100,
  replaySampleRate: 100,
  trackInteractions: true,
  defaultPrivacyLevel: 'allow',
});

// eslint-disable-next-line no-unused-expressions
import('./bootstrap');
